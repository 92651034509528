/**
 * Auto suggest error
 */
export class AutosuggestError extends Error {
  /**
   * @param {string} message - Error message
   */
  constructor (message) {
    super(message)

    this.name = this.constructor.name

    Error.captureStackTrace(this, this.constructor)
  }
}
