<template>
  <transition name="el-zoom-in-top">
    <div
      v-show="parent.activated"
      class="el-autocomplete-suggestion el-popper el-autocomplete-suggestion-mobile"
      :class="{ 'is-loading': !parent.hideLoading && parent.loading }"
      role="region">
      <div class="el-autocomplete-suggestion__wrap_mobile">
        <ul class="el-autocomplete-suggestion__list">
          <li v-if="!parent.hideLoading && parent.loading">
            <i class="el-icon-loading" />
          </li>
          <slot v-else />
        </ul>
      </div>
    </div>
  </transition>
</template>
<script>
import Emitter from 'element-ui/src/mixins/emitter'

export default {
  mixins: [
    Emitter
  ],
  componentName: 'AutocompleteSuggestionsMobile',

  props: {
    options: {
      type: Object,
      default () {
        return {
          gpuAcceleration: false
        }
      }
    },
    id: String
  },
  data () {
    return {
      parent: this.$parent
    }
  },

  mounted () {
    this.$parent.popperElm = this.popperElm = this.$el
    this.referenceElm = this.$parent.$refs.input.$refs.input
    this.referenceList = this.$el.querySelector('.el-autocomplete-suggestion__list')
    this.referenceList.setAttribute('role', 'listbox')
    this.referenceList.setAttribute('id', this.id)
  },

  created () {
    this.$on('visible', (val, inputWidth) => {
      this.showPopper = val
    })
  },

  methods: {
    select (item) {
      this.dispatch('Autocomplete', 'item-click', item)
    }
  }
}
</script>
