<template>
  <div
    v-if="ratings > 0"
    class="star-ratings">
    <!--    <span class="star-ratings-item-bg">-->
    <i
      v-for="n in Math.round(ratings)"
      :key="'start' + n"
      class="el-icon-star-on star-ratings-item" />
    <!--      </span>-->
    <!--    <i-->
    <!--        class="el-icon-star-on star-ratings-item-off"-->
    <!--        v-for="n in (5-ratings)" :key="'startoff' + n"/>-->
  </div>
  <div
    v-else
    class="star-ratings">
    <i
      v-for="n in 5"
      :key="'start' + n"
      class="el-icon-star-on star-ratings-item-off" />
  </div>
</template>

<script>
export default {
  name: 'StarRatings',
  props: {
    ratings: {
      type: Number, // required: true,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .star-ratings-item-bg {
    background-color: $star-rating-bg-color;
  }
  .star-ratings-item {
    color: $star-rating-color !important;
  }
  .star-ratings-item-off {
    color: $star-rating-off-color;
  }

  .star-ratings-item-bg {

  }
</style>
