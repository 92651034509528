<template>
  <el-popover
    v-model="topCitiesVisibility"
    placement="bottom-start"
    trigger="manual">
    <autocomplete
      id="location-query"
      slot="reference"
      ref="autocomplete"
      v-model="localValue.locationQuery"
      prefix-icon="el-icon-location-outline"
      :title="$t('components.LocationSearch.autocomplete.title')"
      popper-class="advance-autocomplete"
      class="input-block"
      value-key="name"
      :fetch-suggestions="queryAutosuggest"
      :debounce="300"
      :trigger-on-focus="false"
      :placeholder="$t('components.LocationSearch.autocomplete.placeholder')"
      :popper-options="{ boundariesElement: 'srcollParent' }"
      :popper-append-to-body="false"
      hide-loading
      @select="handleAutosuggestSelect"
      @focus="handleFocus"
      @blur="handleBlur">
      <i
        v-show="value.locationQuery"
        slot="suffix"
        class="el-icon-close el-input__icon"
        @click="handleClearClick" />
      <template slot-scope="{ item }">
        <div
          v-if="item.name"
          class="list is-gapless">
          <div class="list-middle">
            <i
              v-if="item.data.display_type === typesList.CITY"
              class="el-icon-location" />
            <i
              v-if="item.data.display_type === typesList.HOTEL"
              class="el-icon-office-building" />
            <IconBase
              v-if="[typesList.AIRPORT, typesList.METROCODE].includes(item.data.display_type)"
              class="icon-svg"
              width="16"
              height="16"
              viewBox="0 0 25 25"
              icon-name="icon-airport">
              <IconAirport />
            </IconBase>
            <IconBase
              v-if="item.data.display_type === typesList.POI"
              class="icon-svg"
              width="20"
              height="20"
              viewBox="0 0 25 25"
              icon-name="icon-poi">
              <IconPoi />
            </IconBase>
          </div>
          <div class="list-content">
            <div
              v-if="item.data.district"
              class="location--item">
              <div>
                <div class="has-text-bold">
                  {{ item.data.district }}
                </div>
                <div>
                  <span>{{ item.data.city }}</span>
                  <span v-if="item.data.country">, </span>
                  <span>{{ item.data.country }}</span>
                </div>
              </div>
              <div
                v-if="item.data.iata"
                class="hotel-count">
                {{ item.data.iata }}
              </div>
              <!-- <div
                v-else-if="item.data.hotel_count"
                class="hotel-count">
                {{ item.data.hotel_count | number }} {{ $t('app.hotels') }}
              </div> -->
            </div>
            <div
              v-else
              class="location--item">
              <div>
                <div class="has-text-bold">
                  {{ item.data.city }}
                </div>
                <div>
                  {{ item.data.country }}
                </div>
              </div>
              <!-- <div
                v-if="item.data.hotel_count"
                class="hotel-count">
                {{ item.data.hotel_count | number }} {{ $t('app.hotels') }}
              </div> -->
            </div>
          </div>
        </div>
        <div
          v-if="!item.name && errorMessage"
          class="auto-suggest-no-result">
          <span class="has-text-danger">
            <i class="el-icon-warning" />
            {{ errorMessage }}
          </span>
        </div>
        <div
          v-if="!item.name && !errorMessage"
          class="auto-suggest-no-result">
          {{ $t('app.no-result') }}
        </div>
        <!-- <div class="list" v-if="item.typm pe === 'airport'">
          <div class="list-middle">
            <img :src="item.data.icon" />
          </div>
          <div class="list-content">
            <div>{{ item.data.name }}</div>
            <div class="list-label">{{ item.data.formatted_address }}</div>
          </div>
        </div> -->
        <!-- <div class="list" v-if="item.type === 'trainStation'">
          <div class="list-middle">
            <img :src="item.data.icon" />
          </div>
          <div class="list-content">
            <div>{{ item.data.name }}</div>
            <div class="list-label">{{ item.data.formatted_address }}</div>
          </div>
        </div>
        <div class="list" v-if="item.type === 'address'">
          <div class="list-middle">
            <i class="el-icon-location-outline" />
          </div>
          <div class="list-content">
            <div>{{ item.data.description }}</div>
          </div>
        </div> -->
      </template>
    </autocomplete>
    <div
      ref="topCities"
      class="card-top-city"
      :class="mode">
      <div
        v-if="recentSearches.length"
        class="recent-search">
        <div class="has-text-weight-bold has-text-primary">
          {{ $t('components.LocationSearch.recent-searches') }}
        </div>
        <ul>
          <li
            v-for="(item) in recentSearches"
            :key="item.id">
            <a @click.prevent="handleAutosuggestSelect(item)">{{ item.city }}</a>
          </li>
        </ul>
        <hr style="margin: 15px 0;">
      </div>
      <div
        class="has-text-weight-bold has-text-primary">
        {{ $t('components.LocationSearch.top-cities') }}
      </div>
      <ul>
        <li
          v-for="(item) in topCities"
          :key="item.id">
          <a @click.prevent="handleAutosuggestSelect(item)">{{ item.city }}</a>
        </li>
      </ul>
    </div>
  </el-popover>
</template>

<script>
import find from 'lodash/find'
import axios from 'axios'
import { mapState } from 'vuex'
import { CancelToken, isCancel } from '@/service/api'
import { debug, useGeoRadiusRegion } from '@/plugins/util'
// import topCities from '../mock/top-cities'
import Autocomplete from './Autocomplete'
import IconAirport from './icons/IconAirport'
import IconPoi from './icons/IconPoi'
import { getCountryByAlpha2 } from '@/plugins/countriesHelper'
import { AutosuggestError } from '@/service/errors'

const TYPES_LIST = {
  CITY: 'city',
  POI: 'poi',
  HOTEL: 'hotel',
  AIRPORT: 'airport',
  METROCODE: 'metrocode'
}

export default {
  name: 'LocationSearch',
  components: {
    Autocomplete,
    IconAirport,
    IconPoi
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    mode: {
      //  horizontal / vertical
      type: String,
      default: 'horizontal'
    }
  },
  data () {
    return {
      retries: 0,
      localValue: {},
      topCitiesVisibility: false,
      timerHandle: null,
      input: undefined,
      highlightedIndex: -1,
      cancelSource: undefined,
      errorMessage: undefined,
      typesList: TYPES_LIST
    }
  },
  computed: {
    ...mapState('searchMany', ['recentSearches']),
    topCities () {
      return this.$i18n.locale === 'zh-CN' ? require('./top-cities-cn.json') : require('./top-cities.json')
    },
    suggestions () {
      return [...this.recentSearches, ...this.topCities]
    }
  },
  watch: {
    value: {
      deep: true,
      handler (nV) {
        this.localValue = nV
      }
    },
    highlightedIndex (nV) {
      const suggestionList = this.$refs.topCities.querySelectorAll('li')
      const preHighlightItem = this.$refs.topCities.querySelector('li.highlight')
      if (preHighlightItem) preHighlightItem.classList.remove('highlight')
      if (nV >= 0) {
        let highlightItem = suggestionList[nV]
        highlightItem.classList.add('highlight')
      }
    }
  },
  created () {
    this.localValue = this.value
    this.$store.dispatch('searchMany/getRecentSearches')
  },
  mounted () {
    this.$nextTick(() => {
      this.input = this.$refs.autocomplete.$refs.input.$el.querySelector('input')
      this.input.addEventListener('keydown', this.handelKeyBoard)
    })
  },
  beforeDestroy () {
    this.input.removeEventListener('keydown', this.handelKeyBoard)
  },
  methods: {
    handleFocus (e) {
      // if (!this.$mq.mobile) smoothScroll.scrollTo('#location-query', { offset: -20 })
      if (!this.$refs.autocomplete.suggestionVisible) {
        this.topCitiesVisibility = true
      }
    },
    handleBlur (e) {
      this.timerHandle = setTimeout(() => {
        this.topCitiesVisibility = false
      }, 300)
    },
    handleClearClick () {
      this.localValue = {
        regionId: undefined,
        locationQuery: ''
      }
      this.$emit('input', this.localValue)
      this.focusInput()
    },
    async queryAutosuggest (queryString, cb) {
      this.topCitiesVisibility = false
      /**
       * should cancel previous request if any
       * otherwise it will caused result overriden
       */
      if (this.cancelSource) {
        this.cancelSource.cancel('new search')
      }
      const blankResults = [
        {
          id: 0,
          name: ''
        }
      ]
      const trimed = queryString.trim()
      if (trimed === '') return cb(blankResults)
      try {
        this.cancelSource = CancelToken.source()
        const autoSuggestAPI = axios({
          url: `${process.env.VUE_APP_STATIC_DATA_SERVICE_URL}/api/autosuggest`,
          params: {
            fuzzy: true,
            term: trimed,
            limit: 5,
            propertiesStatus: 'open',
            propertiesLimit: 10,
            locale: this.$i18n.locale.toLowerCase(),
            types: [TYPES_LIST.CITY, TYPES_LIST.POI, TYPES_LIST.HOTEL].join(),
            version: 'v2'
          },
          cancelToken: this.cancelSource.token,
          headers: {
            'x-api-key': process.env.VUE_APP_STATIC_DATA_API_KEY
          }
        })
        const autoSuggestAirportAPI = axios({
          url: `${process.env.VUE_APP_STATIC_DATA_SERVICE_URL}/api/autosuggest/airports`,
          params: {
            term: trimed,
            limit: 3,
            locale: this.$i18n.locale.toLowerCase(),
            types: [TYPES_LIST.AIRPORT, TYPES_LIST.METROCODE].join()
          },
          cancelToken: this.cancelSource.token,
          headers: {
            'x-api-key': process.env.VUE_APP_STATIC_DATA_API_KEY
          }
        })
        const [hotelResponse, airportResponse] = await Promise.all([autoSuggestAPI, autoSuggestAirportAPI])
        const { city: cityList = [], poi, hotel } = hotelResponse.data
        const airport = airportResponse.data
        const outlets = { results: [] }

        const matchingAirport = find(airport, function (item) {
          const query = trimed.toLowerCase()
          const iata = item.data.iata || ''
          return iata.toLowerCase() === query
        })
        const hasMatchingAirport = matchingAirport !== undefined

        // is querying hotel name
        let isQueryHotel = false
        const regex = new RegExp(this.$store.state.hotelNames.map((w) => '\\b' + w + '\\b').join('|'), 'g')
        if (regex.test(queryString.toLowerCase())) {
          isQueryHotel = true
        }

        // Remove duplicate location
        const cityNameList = cityList.map(item => item.name)
        const duplicateCityNameList = cityNameList.filter((item, index) => cityNameList.indexOf(item) !== index)
        const city = []
        for (const cityItem of cityList) {
          if (duplicateCityNameList.length) {
            for (const duplicateCityName of duplicateCityNameList) {
              if (cityItem.name === duplicateCityName) {
                const hasBeenAdded = city.some(item => item.name === cityItem.name)
                if (hasBeenAdded) {
                  continue
                } else {
                  const cityWithHighestHotelCount = cityList.filter(item => item.name === cityItem.name).sort((prev, next) => next.data.hotel_count - prev.data.hotel_count)
                  city.push(cityWithHighestHotelCount[0])
                }
              } else {
                city.push(cityItem)
              }
            }
          } else {
            city.push(cityItem)
          }
        }

        if (city.length) {
          outlets.results.push(...city)
        }

        if (poi) {
          outlets.results.push(...poi)
        }

        if (hotel) {
          if (isQueryHotel) {
            outlets.results.unshift(...hotel)
          } else {
            outlets.results.push(...hotel)
          }
        }

        if (airport) {
          const filteredAirport = airport.map((item) => {
            const isGroups = !!item.groups
            if (isGroups) {
              return item.groups.find((group) => {
                const query = trimed.toLowerCase()
                const iata = group.data.iata || ''
                return iata.toLowerCase() === query
              })
            }
            return item
          }).filter(Boolean)

          if (hasMatchingAirport) {
            outlets.results.unshift(...filteredAirport)
          } else {
            outlets.results.push(...filteredAirport)
          }
        }

        if ((hotelResponse === null || airportResponse === null) || outlets.results.length === 0) return cb(blankResults)
        /**
         * For type "city" and "airport" they have district, city, and country.
         * For type "hotel" it will follows "city" & "airport"
         * distrcict = hotel name
         * city = hotel district
         * country = empty
         */
        const results = outlets.results.map(obj => {
          if ([TYPES_LIST.CITY, TYPES_LIST.POI].includes(obj.data.display_type)) {
            const nameArr = obj.name.split(',')
            if (nameArr.length === 1) {
              obj.data.city = nameArr[0]
              obj.data.district = ''
              obj.data.country = ''
            } else if (nameArr.length < 3) {
              obj.data.district = ''
              obj.data.city = nameArr[0]
              obj.data.country = nameArr[1].trim()
            } else {
              obj.data.district = nameArr[0]
              obj.data.city = nameArr[1]
              obj.data.country = nameArr[2]
            }
          } else if ([TYPES_LIST.AIRPORT, TYPES_LIST.METROCODE].includes(obj.data.display_type)) {
            // for airport, only set district with name
            obj.data.district = obj.name
          } else {
            /**
             * Displaying hotel.
             * Found weird name, and all of them contain packages info.
             * ex: Rental Villa Marinada 1 - Alcanar, 9 Bedrooms, 22 Persons
             * we should normalize it
             */
            if (obj.name.includes('-')) {
              let normalized = obj.name.split('-')
              obj.data.district = normalized[0]
            } else {
              obj.data.district = obj.name
            }
            if (obj.data.country) {
              const c = getCountryByAlpha2(obj.data.country)
              obj.data.country = c ? c.lb : ''
            }
          }
          return obj
        })

        // eslint-disable-next-line standard/no-callback-literal
        return cb(results)
      } catch (err) {
        const response = err.response
        if ((response && response.data.message && response.data.message.toLowerCase().indexOf('timeout') > -1) ||
          (response && response.status === 429)) {
          this.retries += 1
          if (this.retries <= 20) {
            setTimeout(() => {
              this.queryAutosuggest(queryString, cb)
            }, 500) // wait for 500ms then try again
          } else {
            this.$store.commit(
              'SET_GLOBAL_MODAL',
              {
                show: true, content: this.$t('app.error.timeout.content')
              }
            )
            this.retries = 0
          }
        } else {
          if (isCancel(err)) {
            if (debug) console.warn(`Cancel: ${err.message}`)
            return
          }
          if (err.meta) this.$store.commit('meta/SET_META_ACTION', err.meta)
          if (err.error || err.message) {
            if (err.message !== 'Network Error') {
              this.errorMessage = 'Network error'
            }
            const blankResults = [
              {
                id: 0,
                name: ''
              }
            ]
            return cb(blankResults)
            // this.$store.commit(
            //   'SET_GLOBAL_MODAL',
            //   {
            //     show: true,
            //     content: this.$t(`error.${err.error && err.error.code}`) || err.message || (err.error && err.error.message) || ''
            //   }
            // )
          }
        }

        throw new AutosuggestError(err.message)
      }
    },
    handleAutosuggestSelect (val) {
      const { name, id, term } = val
      this.highlightedIndex = -1
      //  this.focusInput()
      if (id) {
        let type = val.data ? val.data.display_type : TYPES_LIST.CITY
        let isUseGeoRadius = useGeoRadiusRegion(id)
        if (isUseGeoRadius) type = TYPES_LIST.AIRPORT
        const query = {
          regionId: id,
          locationQuery: name || term,
          regionName: name || term,
          type: type
        }
        if (!val.data || type === TYPES_LIST.CITY) {
          this.$store.dispatch('searchMany/updateRecentSearches', val)
        } else if ([TYPES_LIST.AIRPORT, TYPES_LIST.POI, TYPES_LIST.METROCODE].includes(type)) {
          query.lat = val.data.latitude
          query.lng = val.data.longitude
          query.radius = 7000
          if (isUseGeoRadius) query.radius = 6000
          query.type = type === TYPES_LIST.METROCODE ? TYPES_LIST.AIRPORT : type
        }
        this.$emit('input', query)
      }
    },
    handleKeyEnter (e) {
      if (this.topCitiesVisibility && this.highlightedIndex >= 0 && this.highlightedIndex < this.suggestions.length) {
        e.preventDefault()
        this.handleAutosuggestSelect(this.suggestions[this.highlightedIndex])
        this.topCitiesVisibility = false
      }
    },
    handleKeyUp (e) {
      if (this.topCitiesVisibility) {
        e.preventDefault()
        this.highlight(this.highlightedIndex - 1)
      }
    },
    handleKeyDown (e) {
      if (this.topCitiesVisibility) {
        e.preventDefault()
        this.highlight(this.highlightedIndex + 1)
      }
    },
    handelKeyBoard (e) {
      switch (e.code) {
      case 'ArrowDown':
      case 'ArrowRight':
        this.handleKeyDown(e)
        break
      case 'ArrowUp':
      case 'ArrowLeft':
        this.handleKeyUp(e)
        break
      case 'Enter':
        this.handleKeyEnter(e)
        break
      case 'Delete':
        this.handleClearClick()
        this.topCitiesVisibility = true
        break
      default:
        break
      }
    },
    highlight (index) {
      if (!this.topCitiesVisibility) { return }
      if (index < 0) {
        this.highlightedIndex = -1
        return
      }
      if (index >= this.suggestions.length) {
        index = this.suggestions.length - 1
      }
      this.highlightedIndex = index
    },
    focusInput () {
      if (this.timerHandle) clearTimeout(this.timerHandle)
      if (!this.$mq.mobile) this.$refs.autocomplete.focus()
      if (!this.$refs.autocomplete.suggestionVisible) {
        this.topCitiesVisibility = true
      }
    }
  }
}
</script>

<style lang="scss">
@import "../styles/bulma-variables";

.el-autocomplete-suggestion li {
  padding: 0 6px !important
}
.input-block {
  width: 100%
}

.auto-suggest-no-result {
  width: 100%;
}

.recent-search {
  text-align: left;
  word-break: keep-all;
}

.location--item {
  display: flex;
  justify-content: space-between;

  // & .hotel-count {
  //   color: $grey-darker;
  //   margin-right: $bleed * 2;
  // }
}
</style>
