<template>
  <el-select
    :value="local"
    autocomplete="off"
    @change="handleChange">
    <el-option
      v-for="it in currencies"
      :key="it"
      :value="it"
      :label="it | currencyLabel" />
  </el-select>
</template>

<script>
export default {
  name: 'InputCurrency',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      // local: '',
      currencies: this.$store.getters['app/supportedCurrencies']
    }
  },
  computed: {
    local: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', this.local)
      }
    }
  },
  // created () {
  //   if (this.value === '') {
  //     this.local = this.$store.getters['app/defaultCurrency']
  //     this.$emit('input', this.local)
  //   } else {
  //     this.local = this.value
  //   }
  // },
  methods: {
    handleChange (nVal) {
      this.$emit('input', nVal)
      this.$emit('change', nVal)
    }
  }
}
</script>

<style scoped>

</style>
