<template>
  <div>
    <!-- width="220" -->
    <el-popover
      v-model="guestFormVisibility"
      placement="bottom"
      trigger="click"
      @show="showed"
      @hide="closed">
      <div :class="{guestWrapperMobile:isMobile}">
        <div
          v-if="isMobile"
          class="guest__mobile-header">
          <div
            class="inputFullScreenHeader"
            @click="guestFormVisibility=false">
            <i class="el-icon-arrow-left" /> {{ $t('components.SearchGuest.title') }}
          </div>
        </div>
        <div class="guestWrapper">
          <section class="list is-gapless">
            <div class="list-content">
              <strong>{{ roomsLabelLocal }}</strong>
            </div>
            <div class="list-action">
              <el-button
                :disabled="roomCount === 1"
                type="text"
                @click="handleRooms(-1)">
                <i class="is-size-4 el-icon-remove-outline" />
              </el-button>
              <el-button
                :disabled="roomCount === maxRoomCount"
                type="text"
                @click="handleRooms(1)">
                <i class="is-size-4 el-icon-circle-plus-outline" />
              </el-button>
            </div>
          </section>
          <section class="list is-gapless">
            <div class="list-content">
              <strong>{{ adultLabelLocal }}</strong>
            </div>
            <div class="list-action">
              <el-button
                :disabled="adultCount === 1"
                type="text"
                @click="handleAdult(-1)">
                <i class="is-size-4 el-icon-remove-outline" />
              </el-button>
              <el-button
                :disabled="adultCount >= maxTotalAdult"
                type="text"
                @click="handleAdult(1)">
                <i class="is-size-4 el-icon-circle-plus-outline" />
              </el-button>
            </div>
          </section>
          <section class="list is-gapless has-border-bottom">
            <div class="list-content">
              <strong>{{ childrenLabelLocal }}</strong>
            </div>
            <div class="list-action">
              <el-button
                :disabled="childrenAges.length === 0"
                type="text"
                @click="handleChildren(-1)">
                <i class="is-size-4 el-icon-remove-outline" />
              </el-button>
              <el-button
                :disabled="isChildrenDisabled"
                type="text"
                @click="handleChildren(1)">
                <i class="is-size-4 el-icon-circle-plus-outline" />
              </el-button>
            </div>
          </section>
          <section
            class="children-ages-container"
            :class="{ 'custom-border': childrenAges.length > 0 }">
            <div
              v-for="(n, idx) in childrenAges"
              :key="'child_' + idx"
              class="list is-gapless">
              <div class="list-content list-title">
                {{ $t('components.SearchGuest.age-of-child') }} {{ idx + 1 }}
              </div>
              <div class="list-action">
                <el-select
                  v-model="childrenAges[idx]"
                  :placeholder="$t('components.SearchGuest.age')"
                  class="child-age"
                  size="small">
                  <el-option
                    v-for="age in childrenAgesOptions"
                    :key="'childAge_' + age"
                    :label="age"
                    :value="age" />
                </el-select>
              </div>
            </div>
          </section>
          <section class="list is-gapless has-border-bottom">
            <div class="list-content list-title">
              {{ $t('total-guests') }} {{ totalGuests }}
            </div>
          </section>
          <section
            v-if="isDesktop"
            class="list is-gapless">
            <div class="list-content">
              <el-button
                class="button-secondary"
                @click="handleApply">
                {{ $t('components.SearchGuest.apply') }}
              </el-button>
            </div>
          </section>
          <section
            v-else
            class="mobile-done-wrapper">
            <el-button
              class="button-secondary"
              @click="handleApply">
              {{ $t('app.done') }}
            </el-button>
          </section>
        </div>
      </div>
      <el-input
        slot="reference"
        prefix-icon="el-icon-user"
        :readonly="true"
        :value="guests" />
    </el-popover>
  </div>
</template>

<script>
const defaultRoomCount = 1
const defaultAdultCount = 2
const MAX_TOTAL_ADULT = 36
const MAX_ADULT_COUNT = 8
const MAX_CHILDREN_COUNT = 2
const MAX_ROOM_COUNT = 6
const MAX_CHILD_AGE = 21

export default {
  name: 'SearchGuest',
  props: {
    value: {
      type: Object,
      required: true
    },
    // Max adult per room
    maxAdultCount: {
      type: Number,
      default: MAX_ADULT_COUNT
    },
    // Max children per room
    maxChildrenCount: {
      type: Number,
      default: MAX_CHILDREN_COUNT
    },
    maxRoomCount: {
      type: Number,
      default: MAX_ROOM_COUNT
    },
    maxChildAge: {
      type: Number,
      default: MAX_CHILD_AGE
    }
  },
  i18n: {
    messages: {
      'en-US': {
        'total-guests': 'Total number of guests:'
      },
      'zh-CN': {
        'total-guests': '客人总数:'
      }
    }
  },
  data () {
    return {
      guestFormVisibility: false,
      roomCount: defaultRoomCount,
      adultCount: defaultAdultCount,
      childrenAges: []
    }
  },
  computed: {
    roomsLabel () {
      return this.value.roomCount > 1 ? `${this.value.roomCount} ${this.$t('components.SearchGuest.rooms')}` : `1 ${this.$t(
        'components.SearchGuest.room')}`
    },
    adultLabel () {
      return this.value.adultCount > 1
        ? `${this.value.adultCount} ${this.$t('components.SearchGuest.adults')}`
        : `1 ${this.$t('components.SearchGuest.adult')}`
    },
    roomsLabelLocal () {
      return this.roomCount > 1 ? `${this.roomCount} ${this.$t('components.SearchGuest.rooms')}` : `1 ${this.$t(
        'components.SearchGuest.room')}`
    },
    adultLabelLocal () {
      return this.adultCount > 1 ? `${this.adultCount} ${this.$t('components.SearchGuest.adults')}` : `1 ${this.$t(
        'components.SearchGuest.adult')}`
    },
    childrenLabel () {
      return this.childrenCount > 1 ? `${this.childrenCount} ${this.$t(
        'components.SearchGuest.children')}` : `${this.childrenCount} ${this.$t('components.SearchGuest.child')}`
    },
    childrenLabelLocal () {
      return this.childrenAges.length > 1 ? `${this.childrenAges.length} ${this.$t(
        'components.SearchGuest.children')}` : `${this.childrenAges.length} ${this.$t('components.SearchGuest.child')}`
    },
    childrenParser () {
      return this.value.children ? this.value.children.split(',') : []
    },
    guests () {
      return `${this.roomsLabel}, ${this.adultLabel}, ${this.childrenLabel}`
    },
    totalGuests () {
      return this.adultCount + this.childrenAges.length
    },
    childrenCount () {
      return this.childrenParser.length
    },
    childrenPerRoom () {
      return Math.floor(this.childrenAges.length / this.roomCount)
    },
    adultPerRoom () {
      return Math.ceil(this.adultCount / this.roomCount)
    },
    isChildrenDisabled () {
      return this.childrenPerRoom === this.maxChildrenCount || this.childrenAges.length >= this.maxTotalChild
    },
    maxGuestsPerRoom () {
      return this.maxAdultCount
    },
    maxTotalAdult () {
      // return this.maxAdultCount * this.maxRoomCount
      return MAX_TOTAL_ADULT
    },
    maxTotalChild () {
      return this.maxChildrenCount * this.maxRoomCount
    },
    isMobile () {
      return this.$mq.mobile
    },
    isTablet () {
      return this.$mq.tabletOnly
    },
    isDesktop () {
      return this.$mq.desktop
    },
    shouldGrowShrink () {
      return (this.isMobile || this.isTablet) && this.childrenAges > 0
    },
    childrenAgesOptions () {
      // age range from 0 - 21
      return [...Array(this.maxChildAge + 1).keys()]
    },
    maxTotalAdultRoom () {
      return this.maxAdultCount * this.roomCount
    },
    maxTotalChildRoom () {
      return this.maxChildrenCount * this.roomCount
    }
  },
  watch: {
    'value': {
      immediate: true,
      handler (nV) {
        this.roomCount = isNaN(nV.roomCount) ? defaultRoomCount : parseInt(nV.roomCount, 10)
        this.adultCount = isNaN(nV.adultCount) ? defaultAdultCount : parseInt(nV.adultCount, 10)
        this.childrenAges = nV.children ? nV.children.split(',') : []
      }
    },
    totalGuests: function (nv) {
      if ((nv / this.roomCount) > this.maxGuestsPerRoom && this.adultPerRoom > this.maxAdultCount) {
        this.roomCount += 1
      }
    },
    roomCount: function (newValue, oldValue) {
      if (newValue < oldValue) {
        this.handleNewGuests()
      }
    }
  },
  methods: {
    showed () {
      this.reset()
      this.$emit('opened')
    },
    closed () {
      this.$emit('closed')
    },
    reset () {
      this.roomCount = isNaN(this.value.roomCount) ? defaultRoomCount : parseInt(this.value.roomCount, 10)
      this.adultCount = isNaN(this.value.adultCount) ? defaultAdultCount : parseInt(this.value.adultCount, 10)
      this.childrenAges = this.value.children ? this.value.children.split(',') : []
    },
    handleRooms (val) {
      this.roomCount = Math.min(this.roomCount + val, this.maxRoomCount)
      if (this.roomCount > this.adultCount) {
        this.adultCount = this.roomCount
      }
    },
    handleAdult (val) {
      this.adultCount = this.adultCount + val
      if (this.adultCount < this.roomCount) {
        this.roomCount = this.adultCount
      }
    },
    handleChildren (val) {
      if (val > 0) {
        this.childrenAges.push(6)
      } else {
        this.childrenAges.pop()
      }
    },
    handleApply () {
      this.$emit('input', {
        adultCount: this.adultCount,
        roomCount: this.roomCount,
        children: this.childrenAges.join(',')
      })
      this.guestFormVisibility = false
    },
    handleNewGuests () {
      if (this.adultCount > this.maxTotalAdultRoom) {
        this.adultCount = this.maxTotalAdultRoom
      }
      if (this.childrenAges.length > this.maxTotalChildRoom) {
        const newChildrenAges = [...this.childrenAges]
        this.childrenAges = newChildrenAges.slice(0, this.maxTotalChildRoom)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .guestWrapperMobile {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;

    .guestWrapper {
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 90px;

      .has-border-bottom {
        border: none !important;
      }
    }
  }

  .guest__mobile-header {
    height: 50px;
    background-color: $primary;
    padding-left: 25px;
    padding-right: 25px;
  }
  .guestWrapper {
    .btn-apply {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .child-age {
    width: 70px;
  }

  .el-button + .el-button {
    @include mobile {
      margin-left: 35px;
    }
  }

  .list .list-content .el-button {
    margin-top: 4px;
    width: 100%;
  }

  .children-ages-container {
    max-height: 100px;
    overflow-y: auto;
  }

  .custom-border {
    border-bottom: 1px solid $list-border-color;
  }
</style>
