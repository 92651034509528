<template>
  <form
    class="location-search-form columns is-multiline"
    :class="mode">
    <div
      :class="columnsClass"
      class="column">
      <label class="search-form-label">{{ $t('components.LocationSearch.autocomplete.label') }}</label>
      <div
        :class="{hasError:locationEmpty}"
        class="input-location-search">
        <LocationSearch
          ref="locationSearch"
          v-model="locationSearchModel"
          :mode="mode"
          style="width: 100%;" />
      </div>
    </div>
    <div
      :class="{...columnsClass, hasError: dateRangeEmpty}"
      class="column is-narrow">
      <label class="search-form-label">{{ $t('components.DateRange.reference.label') }}</label>
      <DatePicker
        ref="dateRange"
        v-model="dateRange"
        class="input-stay-dates" />
    </div>
    <div
      :class="columnsClass"
      class="column is-narrow">
      <label class="search-form-label">{{ $t('components.SearchGuest.title') }}</label>
      <SearchGuest
        v-model="guestModel"
        style="min-width: 200px;" />
    </div>
    <div
      :class="columnsClass"
      class="column  is-narrow">
      <label class="search-form-label">{{ $t('views.share.nationality') }}</label>
      <InputNationality
        v-model="nationality"
        :placeholder="$t('views.share.nationality')"
        class="search-form-input-nationality" />
    </div>
    <div
      :class="columnsClass"
      class="column  is-narrow">
      <label class="search-form-label">{{ $t('views.customer.form.currency.label') }}</label>
      <InputCurrency
        v-model="currency"
        class="search-form-input-currency" />
    </div>
    <div
      :class="columnsClass"
      class="column is-narrow">
      <label class="search-form-label">&nbsp;</label>
      <el-button
        type="primary"
        class="is-pulled-right search__button"
        @click="handleSubmit">
        {{ $t('views.share.search') }}
      </el-button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { retrieveAgent } from '@/service/auth'
import { getCountryByAlpha2 } from '@/plugins/countriesHelper'
import SearchGuest from '../../components/SearchGuest'
import DatePicker from '../../components/DatePicker/index'
import InputNationality from '../../components/InputNationality'
import LocationSearch from '../../components/LocationSearch'
import InputCurrency from '../../components/InputCurrency'
import { useGeoRadiusRegion } from '@/plugins/util'

export default {
  name: 'SearchForm',
  components: {
    InputCurrency,
    LocationSearch,
    InputNationality,
    DatePicker,
    SearchGuest
  },
  props: {
    mode: {
      // horizontal / vertical
      type: String,
      default: 'horizontal'
    }
  },
  data () {
    return {
      isSubmitting: false,
      timeoutHandler: null
    }
  },
  computed: {
    ...mapState('app', [
      'defaultSourceMarket',
      'defaultCurrency'
    ]),
    ...mapGetters('searchFilters', ['filters', 'hasFilter', 'filterCount']),
    // disableSearchBtn () {
    //   return !this.locationSearchModel.locationQuery || !this.dateRange.checkInDate
    // },
    locationEmpty () {
      return !this.locationSearchModel.locationQuery && this.isSubmitting
    },
    dateRangeEmpty () {
      return !this.dateRange.checkInDate && this.isSubmitting
    },
    locationSearchModel: {
      get () {
        let geoPramams = {}
        if (this.$store.state.searchMany.queryParams.hasOwnProperty('lat') && this.$store.state.searchMany.queryParams.hasOwnProperty('lng') && this.$store.state.searchMany.queryParams.hasOwnProperty('radius')) {
          geoPramams = { lat: this.$store.state.searchMany.queryParams.lat, lng: this.$store.state.searchMany.queryParams.lng, radius: this.$store.state.searchMany.queryParams.radius }
        }
        return {
          locationQuery: this.$store.state.searchMany.queryParams.locationQuery,
          regionId: this.$store.state.searchMany.queryParams.regionId,
          ...geoPramams
        }
      },
      set (val) {
        const payload = {
          lat: undefined,
          lng: undefined,
          radius: undefined,
          ...val
        }
        if (val && !this.dateRange.checkInDate) {
          clearTimeout(this.timeoutHandler)
          this.timeoutHandler = setTimeout(() => {
            this.$refs.dateRange.$el.querySelector('input').click()
          }, 300)
        }
        this.$store.commit('searchMany/SET_QUERY_PARAMS', payload)
      }
    },
    dateRange: {
      get () {
        return {
          checkInDate: this.$store.state.searchMany.queryParams.checkInDate,
          checkOutDate: this.$store.state.searchMany.queryParams.checkOutDate
        }
      },
      set (val) {
        if (val && !this.locationSearchModel.locationQuery) {
          clearTimeout(this.timeoutHandler)
          this.timeoutHandler = setTimeout(() => {
            this.$refs.locationSearch.$el.querySelector('input').focus()
          }, 300)
        }
        this.$store.commit('searchMany/SET_QUERY_PARAMS', val)
      }
    },
    guestModel: {
      get () {
        return {
          adultCount: this.$store.state.searchMany.queryParams.adultCount,
          roomCount: this.$store.state.searchMany.queryParams.roomCount,
          children: this.$store.state.searchMany.queryParams.children
        }
      },
      set (val) {
        this.$store.commit('searchMany/SET_QUERY_PARAMS', val)
      }
    },
    nationality: {
      get () {
        return getCountryByAlpha2(this.$store.state.searchMany.queryParams.nationality)
      },
      set (val) {
        this.$store.commit('searchMany/SET_QUERY_PARAMS', { nationality: val.a2 })
      }
    },
    currency: {
      get () {
        return this.$store.state.searchMany.queryParams.currency
      },
      set (val) {
        this.$store.commit('searchMany/SET_QUERY_PARAMS', { currency: val })
      }
    },
    columnsClass () {
      return {
        'is-12': this.mode !== 'horizontal'
      }
    }
  },
  created () {
    // fallback to default sourceMarket
    if (!Object.entries(this.nationality).length) {
      this.$store.commit(
        'searchMany/SET_QUERY_PARAMS',
        { nationality: retrieveAgent('defaultSourceMarket') }
      )
    }

    if (this.currency === undefined) {
      this.$store.commit(
        'searchMany/SET_QUERY_PARAMS',
        { currency: retrieveAgent('defaultCurrency') }
      )
    }
  },
  methods: {
    handleSubmit () {
      this.isSubmitting = true
      if (this.locationEmpty) {
        clearTimeout(this.timeoutHandler)
        this.timeoutHandler = setTimeout(() => {
          this.$refs.locationSearch.$el.querySelector('input').focus()
        }, 300)
        return
      }
      if (this.dateRangeEmpty) {
        clearTimeout(this.timeoutHandler)
        this.timeoutHandler = setTimeout(() => {
          this.$refs.dateRange.$el.querySelector('input').click()
        }, 300)
        return
      }
      this.isSubmitting = false
      if (this.hasFilter) {
        this.$store.commit('searchFilters/CLEAR_DATA') // remove all filter for new search or update search form
        this.$store.commit('searchMany/SET_QUERY_PARAMS',
          {
            categories: undefined,
            facilities: undefined,
            priceRange: undefined,
            starRange: undefined,
            tyRange: undefined,
            propertyName: undefined,
            location: undefined,
            refundable: undefined,
            meals: undefined
          }
        )
        if (this.$store.state.searchMany.queryParams.type !== 'airport' && !useGeoRadiusRegion(this.locationSearchModel.regionId)) {
          this.$store.commit('searchMany/SET_QUERY_PARAMS',
            {
              lat: undefined,
              lng: undefined,
              radius: undefined
            }
          )
        }
      }
      const searchId = this.$newSearchId()
      this.$store.commit('searchMany/SET_QUERY_PARAMS', {
        searchId,
        children: this.guestModel.children || undefined,
        page: 1
      })
      if (this.$store.state.searchMany.queryParams.type === 'hotel') {
        // go to property page, regionId is the propertyCode
        const { regionId, regionName, ...rest } = this.$store.state.searchMany.queryParams
        const routeData = this.$router.resolve({
          name: 'property',
          params: { name: regionName },
          query: { ...rest, propertyCode: regionId }
        })
        // force reload via window
        window.open(routeData.href, '_blank')
      } else if (this.$store.state.searchMany.queryParams.type === 'airport' || useGeoRadiusRegion(this.locationSearchModel.regionId)) {
        this.$emit('search', { ...this.locationSearchModel })
      } else {
        this.$emit('search')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../styles/bulma-variables";
  .search-form-input-currency {
    width: 90px;
    @include fullhd {
      width: 100px;
    }
    .is-12 & {
      width: 100%;
    }
  }

  .search-form-input-nationality {
    width: 110px;
    @include fullhd {
      width: 160px;
    }
    .is-12 & {
      width: 100%;
    }
  }

  .search-form-btn {
    @include fullhd {
      min-width: 180px;
    }
  }

  .search-form-label {
    display: block;
    color: #fff;
    padding-bottom: 3px;
  }

  .vertical .search-form-label {
    color: $text;
  }

  .input-location-search {
    width: 100%;
  }

  .input-stay-dates {
    display:block;
    min-width: 200px;
  }

  .search__button {
    width: 150px;
  }

  .vertical .input-location-search {
    @include fullhd {
      width: 100%;
    }
  }
</style>
<style lang="scss">
  @import "../../styles/bulma-variables";
  .hasError {
    input[type=text] {
      border-color: $danger-color;
    }
  }
</style>
