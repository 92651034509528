<template>
  <el-select
    v-model="localValue"
    filterable
    :placeholder="placeholder"
    value-key="a3">
    <el-option
      v-for="it in filteredCountries"
      :key="it.a3"
      :value="it"
      :label="displayType==='a3'?$t(`countries.${it.a3}`):$t(`countries.${it.a3}`) + ' (' + it[displayType] + ')'" />
  </el-select>
</template>

<script>
import countries from '../plugins/countries'
import whitelistCountries from '../plugins/whitelistCountries'

export default {
  name: 'InputNationality',
  props: {
    value: {
      type: Object
    },
    placeholder: {
      type: String,
      default: 'Nationality'
    },
    displayType: {
      type: String,
      default: 'a3'
    },
    returnType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      countries,
      whitelistCountries
    }
  },
  computed: {
    localValue: {
      set (v) {
        this.$emit('input', v)
        return v
      },
      get () {
        return this.value
      }
    },
    filteredCountries () {
      const whitelistCountriesA2 = Object.keys(whitelistCountries)
      return this.countries.filter(country => whitelistCountriesA2.includes(country.a2))
    }
  }
}
</script>

<style scoped>

</style>
